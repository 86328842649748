import Swal from 'sweetalert2'

export default {
  methods: {
    successMessage(text) {
      return Swal.fire({
        position: 'center',
        showConfirmButton: false,
        icon: 'success',
        title: text,
        timer: 1000,
        timerProgressBar: true
      })
    },
    successDialog(text) {
      return Swal.fire({
        position: 'center',
        showConfirmButton: true,
        icon: 'success',
        title: text
      })
    },
    errorMessage(text) {
      return Swal.fire({
        position: 'center',
        showConfirmButton: false,
        icon: 'error',
        title: text,
        timer: 1000,
        timerProgressBar: true
      })
    }
  }
}
