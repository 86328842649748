<template>
  <v-card>
    <v-card-title>
      <v-icon aria-label="Назад" class="pr-3" @click="$emit('login')">
        mdi-arrow-left
      </v-icon>
      <h1 class="registration-labels">Восстановление пароля</h1>
    </v-card-title>
    <v-stepper elevation="0" v-model="currentStep" vertical>
      <v-stepper-step :complete="mailIsChecked" step="1">
        Введите почту
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-text-field v-model="email" outlined label="Email" class="py-3" />
        <v-btn :disabled="!email" color="primary" @click="checkEmail">
          Далее
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="tokenIsVerified" step="2">
        Введите секретный ключ
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-text-field
          v-model="token"
          outlined
          label="Секретный ключ"
          class="py-3"
        />
        <v-btn :disabled="!token" color="primary" @click="checkToken">
          Далее
        </v-btn>
        <v-btn @click="currentStep--">
          Назад
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="restoreIsSuccess" step="3">
        Придумайте новый пароль
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-text-field v-model="password" outlined label="Пароль" class="pt-3" />
        <v-text-field outlined label="Повторите пароль" class="pb-3" />
        <v-btn :disabled="!password" @click="restorePassword" color="primary">
          Далее
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import NotificationsMixin from '../mixins/NotificationsMixin'

  export default {
    mixins: [NotificationsMixin],

    data() {
      return {
        email: '',
        token: '',
        password: '',
        currentStep: 1,
        mailIsChecked: false,
        tokenIsVerified: false,
        restoreIsSuccess: false
      }
    },
    methods: {
      checkEmail() {
        axios
          .post('auth/password/restore/', { email: this.email })
          .then(() => {
            this.successDialog(
              'На указанную почту был отправлен секретный ключ для восстановления.\n (если ключ не пришел проверьте папку спам и убедитесь в правильности введенных данных)'
            )
            this.currentStep++
            this.mailIsChecked = true
          })
          .catch(error => {
            console.error(error)
            if (!('email' in error.response.data)) {
              this.errorMessage('Ошибка соединения с сервером')
            }
          })
      },
      checkToken() {
        axios
          .post('auth/password/restore/validate_token/', { token: this.token })
          .then(() => {
            this.currentStep++
            this.tokenIsVerified = true
          })
          .catch(error => {
            console.error(error)
            this.errorMessage('Неверный ключ!')
          })
      },
      restorePassword() {
        axios
          .post('auth/password/restore/confirm/', {
            token: this.token,
            password: this.password
          })
          .then(() => {
            this.restoreIsSuccess = true
            this.successMessage('Успешно!')
            this.$emit('login')
          })
          .catch(error => {
            console.error(error)
            this.errorMessage(
              'Ошибка сервера! Проверьте правильность введенных данных и попробуйте еще раз.'
            )
          })
      }
    }
  }
</script>

<style lang="sass" scoped></style>
