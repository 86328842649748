<template>
  <v-container class="h-100">
    <v-row class="justify-content-center align-items-center h-100">
      <keep-alive>
        <component
          :is="isRestore ? 'RestorePassword' : 'LoginForm'"
          @restore="isRestore = true"
          @login="isRestore = false"
        ></component>
      </keep-alive>
    </v-row>
  </v-container>
</template>

<script>
  import RestorePassword from '../RestorePassword'
  import LoginForm from '../LoginForm'
  export default {
    components: { LoginForm, RestorePassword },

    data() {
      return {
        isRestore: false
      }
    }
  }
</script>

<style lang="scss" scoped></style>
