<template>
  <v-container>
    <UserForm v-model="userData" isUpdate />
  </v-container>
</template>

<script>
  import UserForm from '../UserForm'
  import axios from 'axios'
  import NotificationsMixin from '../mixins/NotificationsMixin'

  export default {
    mixins: [NotificationsMixin],
    components: { UserForm },
    data() {
      return {
        userData: {}
      }
    },
    props: {
      user: {
        type: Number,
        required: true
      }
    },
    methods: {
      getUserData(pk) {
        axios
          .get(`auth/update/${pk}/`)
          .then(response => {
            this.userData = response.data
          })
          .catch(error => {
            console.error(error)
            this.errorMessage('Ошибка соединения с сервером!')
          })
      }
    },
    mounted() {
      this.getUserData(this.user)
    }
  }
</script>

<style lang="scss" scoped></style>
