<template>
  <div v-if="news.length > 0">
    <h1 class="main-title text-center pt-5 pb-3">Новости</h1>
    <div>
      <Splide :options="sliderOptions">
        <SplideSlide
          v-for="object in news"
          :key="object.id"
          style="width: 385px"
        >
          <div class="news-slider__item">
            <a :href="object.detail" class="news-slider__link">
              <img
                :src="
                  `${
                    object.image != null ? object.image : 'images/no-image.jpg'
                  }`
                "
                class="news-slider__image"
              />
              <div class="news-slider__description">
                <p class="news-slider__title">{{ object.title }}</p>
                <p class="news-slider__date">
                  {{
                    new Date(object.date).toLocaleString('ru', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })
                  }}
                </p>
              </div>
            </a>
          </div>
        </SplideSlide>
        <template v-slot:controls>
          <div
            v-show="news.length > 1"
            class="news-slider__arrows splide__arrows"
          >
            <div div class="container">
              <div class="row justify-content-between">
                <div class="news-slider__arrow splide__arrow--prev">
                  ← Свежие новости
                </div>
                <div class="news-slider__arrow splide__arrow--next">
                  Предыдущие новости →
                </div>
              </div>
            </div>
          </div>
        </template>
      </Splide>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sliderOptions: {
          type: 'slide',
          rewind: true,
          drag: 'free',
          trimSpace: false,
          perPage: 3,
          gap: '15px',
          pagination: false,
          breakpoints: {
            768: {
              perPage: 1
            }
          },
          padding: 20,
          autoWidth: true
        }
      }
    },
    props: {
      news: Array
    }
  }
</script>

<style lang="sass" scoped>
  .splide__slide
    width: fit-content!important
    padding: 30px 0
</style>
