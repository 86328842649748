<template>
  <v-card :loading="loading" elevation="3" class="pa-3">
    <v-card-title>
      <h1 class="registration-labels">Авторизация</h1>
    </v-card-title>

    <v-form @submit.prevent="handleSubmit" ref="loginForm">
      <v-text-field
        name="email"
        outlined
        label="E-mail"
        required
        type="email"
        :rules="[rules.required, rules.email]"
      />
      <v-text-field
        name="password"
        outlined
        v-model="password"
        label="Пароль"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        required
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :rules="[rules.required, rules.minLength(password, 8)]"
      />
      <v-card-actions class="justify-content-end">
        <v-btn @click="$emit('restore')" type="submit">Забыли пароль</v-btn>
        <v-btn color="primary" :loading="loading" type="submit">Войти</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import FormDataMixin from '../mixins/FormDataMixin'
  import ValidatorsMixin from '../mixins/ValidatorsMixin'
  import NotificationsMixin from '../mixins/NotificationsMixin'

  export default {
    mixins: [FormDataMixin, ValidatorsMixin, NotificationsMixin],

    data() {
      return {
        showPassword: false,
        password: ''
      }
    },
    methods: {
      handleSubmit(event) {
        if (this.$refs.loginForm.validate()) {
          this.sendData(
            'auth/login/',
            this.getFormData(event.target, true),
            {},
            async response => {
              await this.successMessage('Успешно!')
            },
            () => {
              this.errorMessage('Проверьте правильность введенных данных!')
            }
          )
        } else {
          this.errorMessage('Проверьте правильность введенных данных!')
        }
      }
    }
  }
</script>

<style lang="sass" scoped></style>
