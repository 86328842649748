<template>
  <v-container class="registration">
    <v-row>
      <v-col cols="12">
        <div class="registration-title">Регистрация</div>
      </v-col>
      <v-col cols="12">
        <UserForm ref="userForm" :loading="loading" @submit="handleSubmit" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FormDataMixin from '../mixins/FormDataMixin'
  import NotificationsMixin from '../mixins/NotificationsMixin'
  import UserForm from '../UserForm'

  export default {
    mixins: [FormDataMixin, NotificationsMixin],
    components: { UserForm },
    methods: {
      async handleSubmit(userData) {
        if (this.$refs.userForm.validate()) {
          await this.successDialog(
            'Проверьте свой email для подтверждения \n (если письма нет, проверьте папку спам)'
          )
          this.sendData(
            'auth/registration/',
            this.getFormData(userData),
            { 'Content-Type': 'multipart/form-data' },
            async response => {
              await this.successMessage('Пользователь успешно зарегистрирован')
            },
            () => {
              this.errorMessage(
                'Проверьте корректность данных, возможно пользователь с таким E-mail уже существует'
              )
            }
          )
        } else {
          this.errorMessage('Проверьте правильность заполнения формы!')
        }
      }
    },
    mounted() {
      this.$refs.userForm.validate()
    }
  }
</script>
