<template>
  <v-form ref="form" @submit.prevent="$emit('submit', userData)">
    <v-row class="justify-content-center">
      <v-col cols="12" md="4">
        <p class="registration-labels required">Фамилия Имя Отчество</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="userData.lastName"
          outlined
          hide-details="auto"
          label="Фамилия"
          required
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="userData.firstName"
          outlined
          hide-details="auto"
          label="Имя"
          required
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="userData.patronymic"
          outlined
          hide-details="auto"
          label="Отчество"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Фотография</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="userData.photo"
          outlined
          prepend-icon
          hide-details="auto"
          append-icon="mdi-camera"
          accept="image/png, image/jpeg, image/bmp"
          label="Фотография"
          required
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Возраст</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="userData.age"
          outlined
          hide-details="auto"
          v-mask="'##'"
          label="Возраст"
          suffix="лет"
          required
          :rules="[
            rules.required,
            value => value >= 18 || 'Только для совершеннолетних!'
          ]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Место проживания</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="userData.city"
          outlined
          hide-details="auto"
          label="Город"
          required
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Должность</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="userData.position"
          outlined
          hide-details="auto"
          label="Полное наименование должности"
          required
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels">Обучался в РГЭУ(РИНХ)</p>
      </v-col>
      <v-col class="d-flex" cols="12" md="6">
        <v-checkbox
          v-model="userData.studiedInRsue"
          :label="userData.studiedInRsue ? 'Да' : 'Нет'"
          class="m-auto"
          hide-details="auto"
          outlined
        />
      </v-col>
      <transition name="fade">
        <v-col v-if="userData.studiedInRsue" cols="12" md="4">
          <p class="registration-labels required">Годы обучения</p>
        </v-col>
      </transition>
      <transition name="fade">
        <v-col v-if="userData.studiedInRsue" class="d-flex" cols="12" md="6">
          <v-range-slider
            :disabled="!userData.studiedInRsue"
            v-model="studyYears"
            hide-details="auto"
            @change="
              userData.startStudyDate = $event[0]
              userData.endStudyDate = $event[1]
            "
            :max="new Date().getFullYear()"
            :min="1930"
            :rules="[
              value => value[1] - value[0] < 10 || 'Слишком большой диапазон!'
            ]"
          >
            <template v-slot:prepend>
              {{ studyYears[0] }}
            </template>
            <template v-slot:append>
              {{ studyYears[1] }}
            </template>
            <template v-slot:thumb-label="props">
              {{ props.value }}
            </template>
          </v-range-slider>
        </v-col>
      </transition>

      <v-col cols="12" md="4">
        <p class="registration-labels required">Персональные данные</p>
      </v-col>
      <v-col class="d-flex" cols="12" md="6">
        <v-checkbox
          v-model="userData.consentToProcessing"
          :label="userData.consentToProcessing ? 'Согласен' : 'Обработка'"
          class="m-auto"
          hide-details="auto"
          outlined
          required
          :rules="[rules.required]"
        />
        <v-checkbox
          v-model="userData.consentToPublish"
          :label="userData.consentToPublish ? 'Согласен' : 'Публикация'"
          class="m-auto"
          hide-details="auto"
          outlined
          required
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col cols="12">
        <div class="registration-title">Контактная информация</div>
        <p class="registration-subtitle__alpha">
          Данные для входа в личный кабинет
        </p>
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Номер телефона</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          v-model="userData.phone"
          v-mask="'+7 (###)-###-##-##'"
          hide-details="auto"
          required
          :rules="[rules.required, rules.phone]"
          placeholder="(___) ___-__-__"
          label="Телефон"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Электронная почта</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="userData.email"
          outlined
          label="E-mail"
          hide-details="auto"
          placeholder="example@mail.com"
          required
          type="email"
          :rules="[rules.required, rules.email]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels required">Придумайте пароль</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          v-model="userData.password"
          label="Пароль"
          hide-details="auto"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :rules="[rules.required, rules.minLength(userData.password, 8)]"
        />
        <v-text-field
          outlined
          v-model="userData.passwordConfirmation"
          label="Подтверждение пароля"
          hide-details="auto"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :rules="[
            rules.required,
            rules.minLength(userData.passwordConfirmation, 8),
            value => value === userData.password || 'Пароли не совпадают!'
          ]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p class="registration-labels">Социальные сети</p>
      </v-col>
      <v-col cols="12" md="6">
        <UserSocialForm v-model="userData.userSocials" />
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col cols="12" md="6" offset-md="4">
        <v-btn color="#3160e2" x-large :loading="loading" type="submit">
          {{ buttonLabel }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { VueMaskDirective } from 'v-mask'
  import UserSocialForm from '../UserSocialForm'
  import ValidatorsMixin from '../mixins/ValidatorsMixin'

  export default {
    directives: {
      mask: VueMaskDirective
    },
    components: { UserSocialForm },
    mixins: [ValidatorsMixin],

    props: {
      loading: {
        type: Boolean,
        default: false
      },
      buttonLabel: {
        type: String,
        default: 'Отправить'
      }
    },
    data() {
      return {
        userData: {
          firstName: '',
          lastName: '',
          patronymic: '',
          photo: [],
          age: '',
          city: '',
          position: '',
          email: '',
          phone: '',
          password: '',
          studiedInRsue: false,
          consentToProcessing: false,
          consentToPublish: false,
          passwordConfirmation: '',
          userSocials: [],
          startStudyDate: '',
          endStudyDate: ''
        },
        showPassword: false,
        studyYears: [2010, 2020]
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    }
  }
</script>
<style lang="sass">
  .v-input__control
    border-radius: 0!important
  .registration-labels
    margin: 0!important
  .required
    &::before
      content: "* "
      color: red
</style>
