<template>
  <div class="m-2">
    <a class="text-light text-nowrap" target="_blank" :href="url">
      <i :class="icon"></i> {{ name }}
    </a>
  </div>
</template>

<script>
  export default {
    name: 'example-link',
    props: ['icon', 'name', 'url']
  }
</script>
