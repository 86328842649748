<template>
  <div class="build container">
    <div class="row min-vh-100 py-4 align-items-center">
      <div class="col-12 text-center">
        <div class="build__animate">
          <div class="bar"></div>
        </div>
        <div :style="{ color: color }">
          <h3>
            <div
              class="d-inline-block pb-2"
              :style="{ 'border-bottom': `1px solid ${color}` }"
            >
              <slot></slot>
            </div>
          </h3>
          <div v-if="showLinks" class="build__links m-auto">
            <div v-for="category in getCategory" :key="category.name">
              <h6 class="mt-3">
                <b>{{ category.name }}:</b>
              </h6>
              <div class="d-flex flex-wrap justify-content-center">
                <item
                  v-for="item in category.items"
                  :key="item.name"
                  :icon="item.icon"
                  :name="item.name"
                  :url="item.url"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Link from './Link'

  export default {
    components: {
      item: Link
    },
    props: {
      color: {
        type: String,
        default: 'white'
      },
      showLinks: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      getCategory: function() {
        return this.$store.getters['links/items']
      }
    }
  }
</script>

<style lang="sass" scoped>
  @keyframes slide
    from
      background-position: 0
    to
      background-position: 113px

  .build
    .bar
      min-height: 100px
      border-radius: 10px
      width: calc(113px * 6)
      box-shadow: 0px 10px 13px -6px $bar-colour-secondary
      background-color: $bar-colour-secondary
      background-image: repeating-linear-gradient(45deg, transparent, transparent 40px,$bar-colour-primary 40px, $bar-colour-primary 80px)
      animation: slide $bar-speed linear infinite
      will-change: background-position
    &__links
      max-width: 750px
    &__animate
      display: flex
      justify-content: center
      height: 100%
      align-items: center
      padding: 20px
  @include media-breakpoint-down(md)
    .build
      .bar
        width: calc(113px * 4)
  @include media-breakpoint-down(sm)
    .build
      .bar
        width: calc(113px * 2)
</style>
