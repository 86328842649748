<template>
  <v-col cols="12" class="pa-0">
    <v-text-field
      v-model="currentSocial"
      outlined
      placeholder="Напишите ссылку"
      hide-details="auto"
      @click:append-outer="addSocial"
      append-outer-icon="mdi-plus"
    />
    <transition-group name="list">
      <v-text-field
        v-for="(item, index) in socials"
        :key="item.id"
        outlined
        readonly
        hide-details
        :value="item.url"
        @click:append-outer="removeSocial(index)"
        append-outer-icon="mdi-minus"
      />
    </transition-group>
  </v-col>
</template>

<script>
  import NotificationsMixin from '../mixins/NotificationsMixin'

  export default {
    mixins: [NotificationsMixin],

    props: ['value'],
    data() {
      return {
        id: 0,
        currentSocial: '',
        socials: []
      }
    },
    methods: {
      addSocial() {
        if (this.currentSocial) {
          this.socials.push({
            id: this.id++,
            url: this.currentSocial
          })
          this.currentSocial = ''
        } else {
          this.errorMessage('Укажите корректную ссылку')
        }
      },
      removeSocial(index) {
        this.socials.splice(index, 1)
      }
    },
    watch: {
      socials() {
        this.$emit('input', this.socials)
      }
    }
  }
</script>
