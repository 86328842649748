import axios from 'axios'

export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    getFormData(data, isForm = false) {
      if (isForm) return new FormData(data)

      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && !(value instanceof File)) {
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      }
      return formData
    },
    async sendData(url, data, headers = {}, handleSuccess = null, handleError = null) {
      this.loading = true

      await axios
        .post(url, data, {
          headers: headers
        })
        .then(response => {
          handleSuccess && handleSuccess(response)
        })
        .catch(error => {
          console.error(error)
          handleError && handleError()
        })

      this.loading = false
    }
  }
}
