import axios from 'axios'
import Cookies from 'js-cookie'

axios.interceptors.request.use(request => {
  request.headers.common['X-CSRFToken'] = Cookies.get('csrftoken')
  request.baseURL = '/api/v1'
  return request
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 302 || error.response.status === 301) {
    window.location.href = error.response.data.redirectTo
      ? error.response.data.redirectTo
      : window.location.href
    return
  }
  return Promise.reject(error)
})
