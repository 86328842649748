import $ from 'jquery'
import Swal from 'sweetalert2'

$(function() {
  $(document).on('click', 'a[href^="#"]', function(event) {
    event.preventDefault()

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top
      },
      500
    )
  })
  document.querySelectorAll('.requisites__card-value').forEach(el => {
    el.addEventListener('click', event => {
      try {
        navigator.clipboard.writeText(event.currentTarget.innerText)
        Swal.fire({
          text: 'Скопировано!',
          icon: 'success',
          position: 'top-end',
          toast: true,
          timer: 3000,
          customClass: {
            icon: 'copy-icon'
          },
          showConfirmButton: false
        })
      } catch (error) {
        console.log(error)
      }
    })
  })
})
