<template>
  <div class="vue-wrapper">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'app'
  }
</script>

<style lang="sass">
  @import '~style/app'
</style>
