import Vue from 'vue'
import App from './layout/App'
import Example from './ui/Example'
import PatronsPaginate from './PatronsPaginate'
import CapitalCounter from './CapitalCounter'
import NewsSlider from './NewsSlider'
import RegistrationForm from './RegistrationForm'
import LoginPage from './LoginPage'
import PersonalArea from './PersonalArea'
import RestorePassword from './RestorePassword'
import PayloadButton from './PayloadButton'
import CenterInvestPayloadButton from './CenterInvestPayloadButton'
import ReportingList from './ReportingList'
;[
  App,
  {
    name: 'example',
    ...Example
  },
  {
    name: 'patrons-paginate',
    ...PatronsPaginate
  },
  {
    name: 'capital-counter',
    ...CapitalCounter
  },
  {
    name: 'news-slider',
    ...NewsSlider
  },
  {
    name: 'registration-form',
    ...RegistrationForm
  },
  {
    name: 'login-page',
    ...LoginPage
  },
  {
    name: 'personal-area',
    ...PersonalArea
  },
  {
    name: 'restore-password',
    ...RestorePassword
  },
  {
    name: 'payload-button',
    ...PayloadButton
  },
  {
    name: 'center-invest-payload-button',
    ...CenterInvestPayloadButton
  },
  {
    name: 'reporting-list',
    ...ReportingList
  }
  // if not component name
  // { name: 'component-name', ...Component }
].forEach(Component => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.component(Component.name, Component)
})
