<template>
  <div>
    <div @click="showModal = !showModal" class="header__button">
      Внести пожертвования
    </div>
    <transition name="opacity">
      <div
        class="payload__form-mask"
        v-if="showModal"
        @click="showModal = false"
      ></div>
    </transition>
    <transition name="fade">
      <div v-if="showModal" class="payload__form-window">
        <div @click="showModal = false" class="payload__form-window__close">
          <i class="fas fa-times"></i>
        </div>
        <div class="row">
          <h2 class="faq__feedback-title pb-3">Внести пожертвования</h2>
          <div class="col-12">
            <p>Для оплаты заказа, заполните, пожалуйста, информацию о себе.</p>
          </div>

          <div class="col-md-6 py-2 col-12">
            <div class="payload__form-label">Введите Ваше имя:</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              required="required"
              type="text"
              name="fio"
              placeholder="Иванов Иван"
              class="payload__form-input"
            />
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="payload__form-label">
              Введите адрес электронной почты:
            </div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              v-model="email"
              required="required"
              type="text"
              name="email"
              placeholder="myname@mail.ru"
              class="payload__form-input"
            />
          </div>
          <div class="col-md-6 py-2 col-12">
            <div class="payload__form-label">Введите сумму оплаты:*</div>
          </div>
          <div class="col-md-6 py-2 col-12">
            <input
              v-model="amount"
              required="required"
              type="text"
              name="sum"
              placeholder="990"
              class="payload__form-input"
            />
          </div>
          <div class="col-12 offset-md-6 col-md-6">
            <input
              v-model="offerCheckbox"
              type="checkbox"
              id="offer"
              class="mr-2"
            />
            Принимаю
            <span
              style="cursor: pointer; text-decoration: underline"
              @click="
                showOfferModal = !showOfferModal
                showModal = !showModal
              "
            >
              условия договора оферты
            </span>

            &nbsp;
          </div>
          <div class="col-12 offset-md-6 col-md-6">
            <input
              v-model="agreementCheckbox"
              type="checkbox"
              id="personal_data"
              class="mr-2"
            />
            Соглашаюсь на обработку моих
            <span
              style="cursor: pointer; text-decoration: underline"
              @click="
                showAgreementModal = !showAgreementModal
                showModal = !showModal
              "
            >
              персональных данных
            </span>
          </div>
          <div class="offset-md-6 col-md-6 py-2 col-12">
            <button
              :disabled="!(offerCheckbox && agreementCheckbox)"
              class="payload__form-button pt-3"
              @click="order()"
            >
              Перейти к оплате
            </button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showOfferModal" class="payload__form-window row d-flex">
        <div class="col-12" style="height: 600px; overflow: scroll">
          <doc />
        </div>
        <div class="col-12">
          <a
            class="btn btn-primary my-2"
            href="static/documents/public_agreement.pdf"
            target="_blank"
            >Скачать публичный договор</a
          >
        </div>

        <div
          @click="
            showOfferModal = false
            showModal = true
          "
          class="payload__form-window__close"
        >
          <i class="fas fa-times"></i>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showAgreementModal" class="payload__form-window">
        <div style="height: 600px; overflow: scroll">
          <aception />
        </div>
        <div
          @click="
            showAgreementModal = false
            showModal = true
          "
          class="payload__form-window__close"
        >
          <i class="fas fa-times"></i>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import doc from './doc.vue'
  import aception from './aception.vue'

  const body = document.querySelector('body')
  export default {
    name: 'CenterInvestPayloadButton',
    components: {
      doc,
      aception
    },
    data() {
      return {
        email: '',
        amount: 0,
        showModal: false,
        showOfferModal: false,
        showAgreementModal: false,
        offerCheckbox: false,
        agreementCheckbox: false
      }
    },
    mounted() {
      ;(function (w, d, s, o, f, js, fjs) {
        w.CibIntrAcq = o
        w[o] =
          w[o] ||
          function () {
            ;(w[o].q = w[o].q || []).push(arguments)
          }
        // eslint-disable-next-line no-unused-expressions, no-sequences
        ;(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])
        js.id = o
        js.src = f
        js.async = 1
        fjs.parentNode.insertBefore(js, fjs)
      })(
        window,
        document,
        'script',
        '__cia',
        '//www.centrinvest.ru/widgets/payment.js'
      )

      window.__cia('init', {
        token: 'TXVsdGlNZXJjaGFudC8rNzkxODUxNzQ3NzA6aTM/dXBsTW83',
        terminalId: 61
      })
    },
    methods: {
      order() {
        window.__cia('order', {
          amount: this.amount,
          email: this.email,
          description: 'Пожертвование',
          rid: 1
        })
      }
    },
    watch: {
      showModal: function () {
        if (this.showModal) {
          body.style.overflow = 'hidden'
        } else {
          body.style.overflow = 'initial'
        }
      },
      showOfferModal: function () {
        if (this.showOfferModal) {
          body.style.overflow = 'hidden'
        } else {
          body.style.overflow = 'initial'
        }
      },
      showAgreementModal: function () {
        if (this.showAgreementModal) {
          body.style.overflow = 'hidden'
        } else {
          body.style.overflow = 'initial'
        }
      }
    }
  }
</script>
