import './axios'
import 'bootstrap'
import './jquery'
import './iconfont'
import Vuelidate from 'vuelidate'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'
import Vue from 'vue'
import VueSplide from '@splidejs/vue-splide'
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.use(VueSplide)

window.Popper = require('popper.js').default

Vue.use(Vuelidate)
