<template>
  <div class="row pt-3">
    <div class="col-12">
      <transition-group name="flip"  class="row">
        <div
          class="col-lg-3 col-6 my-4"
          v-for="patron in page"
          :key="patron.id"
        >
          <div class="patrons__wrapper">
            <img
              :src="
                patron.photo !== ''
                  ? '/media/' + patron.photo
                  : '/static/images/no-image.png'
              "
              :alt="patron.name"
              class="patrons__image img-fluid"
            />
            <div class="patrons__name mt-3">{{ patron.name }}</div>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="col-12">
      <div class="paginate__buttons">
        <div
          class="paginate__button"
          :class="selectedPage == 1 ? 'paginate__button-disabled' : ''"
          @click="selectedPage--"
        >
          &#171;
        </div>
        <div
          v-for="page in coutOfPages"
          :key="page"
          class="paginate__button"
          :class="selectedPage === page ? 'paginate__button-active' : ''"
          @click="selectedPage = page"
        >
          {{ page }}
        </div>
        <div
          class="paginate__button"
          :class="
            selectedPage == coutOfPages ? 'paginate__button-disabled' : ''
          "
          @click="selectedPage++"
        >
          &#187;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        coutOfPages: Math.ceil(this.patrons.length / this.perPage),
        selectedPage: 1,
        patronsPerPage: []
      }
    },
    props: {
      patrons: Array,
      perPage: {
        type: Number,
        default: 8
      }
    },
    computed: {
      page() {
        return this.getPage(this.selectedPage - 1)
      }
    },
    methods: {
      getPage(page) {
        return this.patrons.slice(
          page * this.perPage,
          page * this.perPage + this.perPage
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
.paginate
  &__buttons
    display: flex
    justify-content: center
  &__button
    display: flex
    justify-content: center
    align-items: center
    margin: 5px
    cursor: pointer
    color: #fff
    background-color: #2A2F7A
    border: 1px solid #2A2F7A
    padding: 15px
    width: 36px
    height: 36px
    border-radius: 100%
    transition: .2s linear
    box-shadow: 0 0 3px #2A2F7A
    &:hover,
    &-active
      color: #2A2F7A
      background-color: #fff
    &-disabled
      pointer-events: none
      opacity: 0.5
      box-shadow: none
.flip
  &-leave,
    &-active,
    &-to
      display: none

.flip
  &-enter
    transform: rotateY(-90deg)
    opacity: 0
    &-to
      transform: rotateY(0deg)
      opacity: 1
    &-active
      transition: all .4s
</style>
