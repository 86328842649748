<template>
  <div ref="counter">
    {{ animatedNumber }} ₽
  </div>
</template>

<script>
  import gsap from 'gsap'

  export default {
    data() {
      return {
        number: 0,
        tweenedNumber: 0
      }
    },
    props: {
      count: String
    },
    computed: {
      animatedNumber() {
        return new Intl.NumberFormat('ru-RU').format(this.tweenedNumber.toFixed(0))
      }
    },
    watch: {
      number(newValue) {
        gsap.to(this.$data, { duration: 1.5, tweenedNumber: newValue })
      }
    },
    mounted() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.number = parseInt(this.count)
          }
        })
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      })
      observer.observe(this.$refs.counter)
    }
  }
</script>

<style lang="sass" scoped>
</style>
