export default {
  data() {
    return {
      rules: {
        required: value => {
          if (value instanceof Array) {
            return value.length !== 0 || 'Обязательное поле!'
          }
          return !!value || 'Обязательное поле!'
        },
        email: value =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'Неправильный email!',
        phone: value =>
          /^(\+7|7|8)\s?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/
            .test(value) || 'Неправильный формат телефона!',
        minLength: (value, minLength) =>
          value.length >= minLength || `Не меньше ${minLength} символов!`
      }
    }
  }
}
