<template>
  <div>
    <nav>
      <div class="nav nav-tabs reporting__filter" id="nav-tab" role="tablist">
        <a
          :key="key"
          v-for="(type, key) in file_types"
          :class="`${selected_type == key ? 'active' : ''}`"
          @click="selected_type = key"
          class="nav-link reporting__tab"
          :id="`nav-${key}-tab`"
          data-toggle="tab"
          :href="`#nav-${key}`"
          role="tab"
          :aria-controls="`nav-${key}`"
          aria-selected="true"
          >{{ type }}</a
        >
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        :key="key"
        v-for="(reportings, key) in filtered_reportings"
        :class="`${selected_type == key ? 'show active' : ''}`"
        class="tab-pane fade"
        :id="`nav-${key}`"
        role="tabpanel"
        :aria-labelledby="`nav-${key}-tab`"
      >
        <div class="m-5" v-if="reportings.length == 0">
          <p class="text-center reporting__no-files">
            В данной категории нет отчётов
          </p>
        </div>
        <div class="m-5" v-else>
          <div
            class="reporting__document row align-items-center mb-4"
            :key="reporting.id"
            v-for="reporting in reportings"
          >
            <img
              src="/static/images/file-icon.svg"
              class="mr-3 reporting__document-image"
            />
            <a :href="`/media/${reporting.file}`" download>{{
              reporting.name
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected_type: 'FIN',
        file_types: {
          FIN: 'Финансовый план',
          PROT: 'Отчёты ДУ',
          ANOTHER: 'Остальное'
        }
      }
    },
    props: {
      reportings: Array
    },
    computed: {
      filtered_reportings() {
        return this.reportings.reduce(
          (acc, curr) => {
            ;(acc[curr.type] = acc[curr.type] || []).push(curr)
            return acc
          },
          { FIN: [], PROT: [], ANOTHER: [] }
        )
      }
    }
  }
</script>
